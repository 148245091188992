
    <img>
        <h1>Class 3</h1>


<a href="tables.html">tables</a>

		<ol>
			<li>Chop potatoes into quarters cm-long (9.8-to-11.8 in) tail, which 
is quite short for a macropod. It has a stocky 
build, well developed hind legs, rounded ears, 
and a short, broad head. Its musculoskeletal sy</li>
			<li>Simmer in salted water for 15-20 minutes until tenderv cm-long (9.8-to-11.8 in) tail, which 
is quite short for a macropod. It has a stocky 
build, well developed hind legs, rounded ears, 
and a short, broad head. Its musculoskeletal sy</li>
			<li>Heat milk, butter and nutmeg cm-long (9.8-to-11.8 in) tail, which 
is quite short for a macropod. It has a stocky 
build, well developed hind legs, rounded ears, 
and a short, broad head. Its musculoskeletal sy</li>
			<li>Drain potatoes and mash cm-long (9.8-to-11.8 in) tail, which 
is quite short for a macropod. It has a stocky 
build, well developed hind legs, rounded ears, 
and a short, broad head. Its musculoskeletal sy</li>
			<li>Mix in the milk mixture</li>
		</ol>
	

<span>
    <img src="images/cliff1.jpg" alt="">
<span class="pic-text">This is a cliff</span>
</span>       

<p> 
A quokka weighs 2.5 to 5.0 kg (5.5 to 11.0 lb) 
and is 40 to 54 cm (16 to 21 in) long with a  
25-to-30 cm-long (9.8-to-11.8 in) tail, which 
is quite short for a macropod. It has a stocky 
build, well developed hind legs, rounded ears, 
and a short, broad head. Its musculoskeletal system 
was originally adapted for terrestrial bipedal 
saltation, but over its evolution, its system has 
been built for arboreal locomotion.[7]

Although looking rather like a very small kangaroo, it can 
climb small trees and shrubs up to 1.5 metres 
(4 ft 11 in).[8] Its coarse fur is a grizzled 
brown colour, fading to buff underneath.
The quokka is known to live for an average of 10 
years.[9] Quokkas are nocturnal animals; <img src="images/quokka.jpg" alt="" width="300px" align="right" />
they sleep during the day in Acanthocarpus preissii, 
using the plants' spikes for protection and hiding.[10]
</p>

Quokkas have a promiscuous mating system.[11] 
After a month of gestation, females give birth 
to a single baby called a joey. Females can give 
birth twice a year and produce about 17 joeys during
 their lifespan.[9] The joey lives in its mother's 
 pouch for six months. Once it leaves the pouch, the 
 joey relies on its mother for milk for two more 
 months and is fully weaned around eight months after 
 birth.[9] Females sexually mature after roughly 18 
 months.[12] When a female quokka with a joey in her 
 pouch is pursued by a predator, she may drop her baby 
 onto the ground; the joey produces noises, which may 
 serve to attract the predator's attention, while the 
 mother escapes.[13]







