
  <a href="example.html">Example HTML</a>
        <h1 class="whatever">Introduction to CSS</h1>
        <h1>Will this have the same font?</h1>

        <p style="
                font-family: 'Courier New', Courier, monospace;
                color: rgb(75, 75, 75);
                font-size: 40px; 
                background-color: white;">
               
                inline Font Family paragraph tag css</p>

        <p class="inlineStyleExample">Using a class insead of inline</p>
        <div class="divClass" id="divTagId" style="">

        </div>
        <p>Is this the same font for the p tag?</p>
        <div>
            this has no CSS or styles added
        </div>
<hr>
<div class="example">
		<h1>pH Scale</h1>
      	<p class="fourteen">14.0 VERY ALKALINE</p>
		<p class="thirteen">13.0</p>
		<p class="twelve">12.0</p>
		<p class="eleven">11.0</p>
		<p class="ten">10.0</p>
		<p class="nine">9.0</p>	
		<p class="eight">8.0</p>
		<p class="seven">7.0 NEUTRAL</p>
		<p class="six">6.0</p>
		<p class="five">5.0</p>
		<p class="four">4.0</p>
		<p class="three">3.0</p>	
		<p class="two">2.0</p>
		<p class="one">1.0</p>
		<p class="zero">0.0 VERY ACID</p>
</div>
