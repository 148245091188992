
        

        <!-- h1 can be whatever you want. think of it as title of an article  -->
        <h1>Class 7 - Lists and Tables continued</h1>
        <h1>The Complete Poems</h1>
        <h2>Emily Dickinson</h2>
            <ol>
                <li>Life</li>
                <li>Nature</li>
                <li>Love</li>
                <li>Time and Eternity</li>
                <li>The Single Hound</li>
            </ol>
            <ul class="illuminations">
                <li>That idol, black eyes and ...That idol, black eyes and ...That idol, black eyes and ...</li>
                <li>Gracious son of Pan! ...That idol, black eyes and ...That idol, black eyes and ...</li>
                <li>When the world is reduced ...That idol, black eyes and ...v</li>
            </ul>
            <ul class="season">
                <li>That idol, black eyes and ...vThat idol, black eyes and ...Once, if my memory serves ...</li>
                <li>That idol, black eyes and ...vThat idol, black eyes and ...Hadn't I once a youth ...</li>
                <li>That idol, black eyes and ...That idol, black eyes and ...Autumn already! ...</li>
            </ul>


<hr>
<h1>
    Table Example
</h1>
<table>
    <tr>
        <th>Author</th>
        <th>Title</th>
        <th class="money">Reserve Price</th>
        <th class="money">Current Bid</th>
    </tr>
    <tr>
        <td>E.E. Cummings</td>
        <td>Tulips & Chimneys</td>
        <td class="money">$2,000.00</td>
        <td class="money">$2,642.50</td>
    </tr>
    <tr class="even">
        <td>Charles d'Orleans</td>
        <td>Poemes</td>
        <td class="money"></td>
        <td class="money">$5,866.00</td>
    </tr>
    <tr>
        <td>T.S. Eliot</td>
        <td>Poems 1909 - 1925</td>
        <td class="money">$1,250.00</td>
        <td class="money">$8,499.35</td>
    </tr>
    <tr class="even">
        <td>Sylvia Plath</td>
        <td>The Colossus</td>
        <td class="money"></td>
        <td class="money">$1031.72</td>
    </tr>
    <tr>
        <td colspan="4">span across row</td>
    </tr>
</table>

<hr>
<h1>Form Example</h1>
<form class="formclass">
    <fieldset>
        <legend>Submission form</legend>
        <input type="text" name="" id="">
        <input type="text" id="email" placeholder="Enter Email">
        <input type="text" id="twitter">
        <input type="text" id="web">
        <button type="submit" id="submit">submit</button>

    </fieldset>
</form>

<hr>
<h1>Grid Example</h1>
<div class="layout">

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <div class="menu">
            <a href="bee">Centering Example</a>
        </div>

        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel earum ullam tenetur possimus iusto nostrum, consectetur dolores architecto! Voluptate ratione expedita itaque placeat sapiente, natus non dolorem fugiat ut sed.
        </p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque suscipit inventore tempora quos repellat, officiis delectus aliquam quisquam odit. Accusantium consectetur molestias voluptatem officiis nihil ullam eos nisi, accusamus blanditiis.</p>

        <p>

        </p>
        <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio nisi mollitia, delectus possimus magni hic dicta suscipit! Deserunt, maxime qui. Incidunt inventore voluptatum accusamus nam error reprehenderit porro, ipsam impedit.
        </p>
</div>