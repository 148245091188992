import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-class3',
  templateUrl: './class3.component.html',
  styleUrls: ['./class3.component.sass']
})
export class Class3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
