import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-class2',
  templateUrl: './class2.component.html',
  styleUrls: ['./class2.component.sass']
})
export class Class2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
