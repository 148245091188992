import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Class1Component } from './class1/class1.component';
import { Class10Component } from './class10/class10.component';
import { Class2Component } from './class2/class2.component';
import { Class3Component } from './class3/class3.component';
import { Class4Component } from './class4/class4.component';
import { Class5Component } from './class5/class5.component';
import { Class6Component } from './class6/class6.component';
import { Class7Component } from './class7/class7.component';
import { Class8Component } from './class8/class8.component';
import { Class9Component } from './class9/class9.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent },
  { path: 'class1', component: Class1Component },
  { path: 'class2', component: Class2Component },
  { path: 'class3', component: Class3Component },
  { path: 'class4', component: Class4Component },
  { path: 'class5', component: Class5Component },
  { path: 'class6', component: Class6Component },
  { path: 'class7', component: Class7Component },
  { path: 'class8', component: Class8Component },
  { path: 'class9', component: Class9Component },
  { path: 'class10', component: Class10Component }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
