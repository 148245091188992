<div class="font-body">
<p class="intro-letter">
     Lipsum dolor sit amet <span class="font-bold">Bold</span>consectetur adipisicing elit. Corporis odio architecto, ratione voluptatibus reprehenderit quam ipsam adipisci magnam, non assumenda ex neque eos iusto doloribus numquam, aut impedit dolore! Officiis.
</p>

<table>
    <tr>
        <td class="item-price">Price:</td>
        <td>$10.99</td>
    </tr>
    <tr>
        <td class="item-price">First Name:</td>
        <td><input type="text"></td>
    </tr>
</table>

<hr>
        <div class="box-size center">
            <p class="par-size">
                Lorem ipsum dolor sit amet, <span class="consecetur">consectetur</span> adipisicing elit. Et assumenda praesentium veritatis commodi. Nihil doloribus ducimus sequi accusamus voluptate dolore maiores commodi voluptatum facere atque a necessitatibus, ut natus fuga.
            </p>
        </div>
        <div class="center">This is a div</div>
        <p class="border-par">this is a paragraph</p>
        <span class="border-par">this is a span</span>
</div>
