import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-class5',
  templateUrl: './class5.component.html',
  styleUrls: ['./class5.component.sass']
})
export class Class5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
