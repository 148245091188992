<div class="nav">
    <ul class="nav">
        <li>

                <a href="home">
                    <img src="../../assets/images/logo.svg" alt="" id="logo">
                    <!-- <object data="../../assets/images/logo.svg" type="image/svg+xml" class="logo">
                    </object> -->
                </a>

        </li>
        <li>
           <a href="class1/">class 1</a>
        </li>
        <li>
           <a href="class2/">class 2</a>
       </li>
       <li>
            <a href="class3/">class 3</a>
       </li> 
       <li>
           <a href="class4/">class 4</a>
       </li>
       <li>
           <a href="class5/">class 5</a>
       </li>
       <li>
           <a href="class6/">class 6</a>
       </li>
       <li>
           <a href="class7/">class 7</a>
       </li>
       <li>
           <a href="class8">class 8</a>
       </li>
    </ul>
</div>