import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bee',
  templateUrl: './bee.component.html',
  styleUrls: ['./bee.component.sass']
})
export class BeeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
