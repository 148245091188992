<div class="home">
    <h1>
        Welcome to the Intro to Web Design web app.
    </h1>


    <!-- <img src="../../assets/images/logo.svg" class="twoh" alt="200 pixels">
    <img src="../../assets/images/logo.svg" class="threeh" alt="300 pixels">
    <img src="../../assets/images/logo.svg" class="fourh" alt="400 pixels">
    <img src="../../assets/images/logo.svg" class="fiveh" alt="500 pixels">
    <img src="../../assets/images/logo.svg" class="sixh" alt="600 pixels"> -->


    <section>
        <figure>
            <a href="http://www.htmlandcssbook.com/">
                <img src="../../assets/images/duckett.jpg" alt="" class="duckett">
            </a>
            <figcaption>Duckett HTML&CSS</figcaption>
        </figure>
        <article>
            The Intro to Web Design class takes the student from the very basics of HTML, to CSS, 
            and simple JavaScript. The course follows along Jon Ducketts HTML & CSS, with updates 
            to bring the class up to date with current standards and practices. 
        </article>
        <article>
            Each class chapter link follows along the ten classes. The pages may or may not be working
            depending on the current point the class is in session or any examples that were focused 
            on during the class. 
        </article>
        <article>
            Duckett provides examples for each chapter. Each class covers roughly two chapters and 
            the examples from each chapter can be found 
            <a href="http://www.htmlandcssbook.com/code-samples/">here.</a>
        </article>
    </section>




    






</div>
