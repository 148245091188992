import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { Class1Component } from './class1/class1.component';
import { HomeComponent } from './home/home.component';
import { Class2Component } from './class2/class2.component';
import { Class3Component } from './class3/class3.component';
import { Class4Component } from './class4/class4.component';
import { Class5Component } from './class5/class5.component';
import { Class6Component } from './class6/class6.component';
import { Class7Component } from './class7/class7.component';
import { Class8Component } from './class8/class8.component';
import { Class9Component } from './class9/class9.component';
import { Class10Component } from './class10/class10.component';
import { BeeComponent } from './class7/bee/bee.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    Class1Component,
    HomeComponent,
    Class2Component,
    Class3Component,
    Class4Component,
    Class5Component,
    Class6Component,
    Class7Component,
    Class8Component,
    Class9Component,
    Class10Component,
    BeeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
