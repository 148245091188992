// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBOHlSsjZNNkYoTYVjUeEMJN1SonBBmh5o",
    authDomain: "introwebdesign-8bcf2.firebaseapp.com",
    projectId: "introwebdesign-8bcf2",
    storageBucket: "introwebdesign-8bcf2.appspot.com",
    messagingSenderId: "703421495732",
    appId: "1:703421495732:web:fe10678260ce72e70ddae1",
    measurementId: "G-0M110THMH1"
  }
};