import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-class9',
  templateUrl: './class9.component.html',
  styleUrls: ['./class9.component.sass']
})
export class Class9Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
